import axios, { AxiosResponse } from 'axios';
import {
    ICategoriesTreeResponse,
    IProductCountByCategoryItem,
    ICategoryTreeItem
} from 'PyzShopUi/scripts/shop-ui/components/search-page/interfaces';

class CategoriesTreeService {
    private categoryTreeApi = '/ajax/category-tree';
    private categoryTreeData: ICategoryTreeItem[] = null;

    public getData = async(): Promise<ICategoryTreeItem[]> => {
        if (this.categoryTreeData) {
            return this.categoryTreeData;
        }

        const dataResponse: AxiosResponse<ICategoriesTreeResponse> = await axios.get(this.categoryTreeApi);
        this.categoryTreeData = this.buildTree(dataResponse.data.categoryTree, 0);

        return this.categoryTreeData;
    };

    public buildTree = (items: ICategoryTreeItem[], level: number, parentKey?: string): ICategoryTreeItem[] => (
        items.map(
            item => (
                {
                    ...item,
                    index: level,
                    parentKey,
                    children: this.buildTree(item.children, level + 1, item.categoryKey),
                }
            ),
        )
    );

    public buildFilterTree = (
        categoryItems: ICategoryTreeItem[],
        selectedCategoryItem: string = 'ROOT',
        parentKey: string = 'ROOT',
        index: number = 0
    ) => (
        categoryItems.map(item => {
            const shouldChildrenBeBuilt = this.shouldChildrenBeBuilt(item, selectedCategoryItem) || parentKey === selectedCategoryItem;
            const childrenItems = shouldChildrenBeBuilt ?
                this.buildFilterTree(item.children, selectedCategoryItem, item.categoryKey, index + 1) : [];

            return {
                ...item,
                index,
                parentKey,
                children: childrenItems,
            };
        })
    );

    public updateCategoryTreeWithCount = (
        categoryTreeItems: ICategoryTreeItem[],
        productCountByCategory: IProductCountByCategoryItem[]
    ): ICategoryTreeItem[]  => (
        categoryTreeItems.map(categoryTreeItem => {
            const foundCategoryCount = productCountByCategory.find(
                (item: IProductCountByCategoryItem) => item.nodeId === categoryTreeItem.id
            ) ?? { count: 0, nodeId: 0 };

            return {
                ...categoryTreeItem,
                name: categoryTreeItem.name + ' (' + foundCategoryCount.count + ')',
                productCount: foundCategoryCount.count,
                children: this.updateCategoryTreeWithCount(categoryTreeItem.children, productCountByCategory),
            }
        })
    );

    private shouldChildrenBeBuilt(categoryItem: ICategoryTreeItem, selectedCategoryItem: string): boolean {
        if (categoryItem.categoryKey === selectedCategoryItem) {
            return true;
        }

        let couldChildrenBeBuilt = false;
        for (const childItem of categoryItem.children) {
            couldChildrenBeBuilt = this.shouldChildrenBeBuilt(childItem, selectedCategoryItem);

            if (couldChildrenBeBuilt === true) {
                break;
            }
        }

        return couldChildrenBeBuilt;
    }
}

export const categoriesTreeService = new CategoriesTreeService();
