import * as React from 'react';
import { withMemo } from 'PyzShopUi/scripts/react/common/hoc/withMemo';

interface IBootBuyerGuideLadingState {
    categoryKey: string;
    bannerContent: string;
}

const categoryKeysHavingBootBuyerGuide = ['c-825', 'c-827', 'c-823', 'c-828', 'c-824', 'c-819', 'c-829', 'c-822', 'c-821', 'c-826', 'm-190'];

const BootBuyerGuideComponent: React.FunctionComponent<IBootBuyerGuideLadingState> = props => {
    const { categoryKey, bannerContent } = props;

    if (!categoryKeysHavingBootBuyerGuide.includes(categoryKey) || !bannerContent) {
        return null;
    }

    return (
        <div dangerouslySetInnerHTML={{ __html: bannerContent }} />
    );
};

export const BootBuyerGuideLanding = withMemo(BootBuyerGuideComponent);
