import * as React from 'react';
import { ICategoryTreeItem } from '../../../interfaces';
import classNames from 'classnames';

interface ICategoryNavigationItemProp {
    item: ICategoryTreeItem;

    onSelect(item: ICategoryTreeItem): void;
}

const CategoryNavigationSubItem: React.FunctionComponent<ICategoryNavigationItemProp> = props => {
    const { item, onSelect } = props;

    const clickHandler = () => {
        const customContainer = document.getElementById("custom-container");
        customContainer.classList.add("slide-in-from-left");
        onSelect(item);
        customContainer.addEventListener("animationend", () => {
            customContainer.classList.remove("slide-in-from-left");
            const backButton = document.getElementsByClassName("button-category-back")?.[0] as HTMLElement;
            backButton && backButton.focus();
        });
    };

    const itemClassName = classNames(
        `navigation-section__navigation-content-children-item`,
        { disable: item.productCount === 0 },
    );

    return (
        <li className={itemClassName}>
            <div
                className="navigation-section__navigation-content-children-item__button"
                onClick={clickHandler}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        clickHandler()
                    }
                }}
                role="button"
                tabIndex={0}
            >
                {item.name}
            </div>
        </li>
    );
};

export default CategoryNavigationSubItem;
