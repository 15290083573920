import { Reducer } from 'react';
import {
    doUpdateActiveCategoryName,
    doUpdateHeadTitle
} from '../../shop-ui/components/search-page/reducer/catalog';
import { Action } from '../../shop-ui/includes/enhanced-reducer';
import {
    doUpdateActiveCategory,
    doUpdateCategoriesTree,
    doUpdateNavigationActiveCategoryKey
} from '../../shop-ui/components/search-page/reducer/categoriesTree';
import FilterStateService from '../../shop-ui/components/search-page/services/FilterStateService';
import {
    beginSearchPageLoading,
    endSearchPageLoading,
    updateActiveCategoryName,
    updateHeadTitle,
    updateSearchPageState,
    updateSkipUpdateFromApiQueryParams
} from '../../shop-ui/components/search-page/actions/searchPage';
import {
    storeCategoriesTree,
    updateActiveCategory,
    updateNavigationActiveCategoryKey
} from '../../shop-ui/components/search-page/actions/categoriesTree';
import {
    resetAllFilter,
    setCategoryFilterOption,
    setListFilterOption,
    setRangeFilterOption,
    setToggleFilterOption
} from '../../shop-ui/components/search-page/actions/filter';
import { setPagination } from '../../shop-ui/components/search-page/actions/pagination';
import { setPerPage } from '../../shop-ui/components/search-page/actions/perPage';
import { setSort } from '../../shop-ui/components/search-page/actions/sort';
import {
    doResetAllFilter,
    doSetCategoryFilterOption,
    doSetListFilterOption,
    doSetRangeFilterOption,
    doSetToggleFilterOption
} from '../../shop-ui/components/search-page/reducer/filter';
import {
    doUpdatePageLoading,
    doUpdatePageState,
    doUpdateSkipBaseOnApiQueryParams
} from '../../shop-ui/components/search-page/reducer/searchPage';
import { doSetPagination } from '../../shop-ui/components/search-page/reducer/pagination';
import { doSetPerPage } from '../../shop-ui/components/search-page/reducer/perPage';
import { doSetSort } from '../../shop-ui/components/search-page/reducer/sort';
import { ISearchPageActionState } from '../../shop-ui/components/search-page/interfaces';
import { setViewMode } from '../../shop-ui/components/search-page/actions/viewModeSwitch';
import { doSetViewMode } from './viewMode';

export const createCatalogReducer = (filterStateService: FilterStateService) => {
    // @ts-ignore
    const catalogPageReducer: Reducer<ISearchPageActionState, Action<any>> = (state, action) => {
        switch (action.type) {
            case updateSearchPageState.type:
                return doUpdatePageState(state, action.payload);
            case beginSearchPageLoading.type:
                return doUpdatePageLoading(state, true);
            case endSearchPageLoading.type:
                return doUpdatePageLoading(state, false);
            case setListFilterOption.type:
                return doSetListFilterOption(state, action.payload, filterStateService);
            case setToggleFilterOption.type:
                return doSetToggleFilterOption(state, action.payload, filterStateService);
            case setRangeFilterOption.type:
                return doSetRangeFilterOption(state, action.payload, filterStateService);
            case setPagination.type:
                return doSetPagination(state, action.payload);
            case setPerPage.type:
                return doSetPerPage(state, action.payload);
            case setSort.type:
                return doSetSort(state, action.payload);
            case resetAllFilter.type:
                return doResetAllFilter(state, filterStateService);
            case storeCategoriesTree.type:
                return doUpdateCategoriesTree(state, action.payload);
            case updateNavigationActiveCategoryKey.type:
                return doUpdateNavigationActiveCategoryKey(state, action.payload);
            case updateActiveCategory.type:
                return doUpdateActiveCategory(state, action.payload);
            case updateSkipUpdateFromApiQueryParams.type:
                return doUpdateSkipBaseOnApiQueryParams(state, action.payload);
            case updateActiveCategoryName.type:
                return doUpdateActiveCategoryName(state, action.payload);
            case updateHeadTitle.type:
                return doUpdateHeadTitle(state, action.payload);
            case setCategoryFilterOption.type:
                return doSetCategoryFilterOption(state, action.payload, filterStateService);
            case setViewMode.type:
                return doSetViewMode(state, action.payload);
            default:
                return state;
        }
    }

    return catalogPageReducer;
};
