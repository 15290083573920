import * as React from 'react';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';
import SortSelect from './SortSelect';
import MdcPerPageSelect from './MdcPerPageSelect';
import Filters from './filters/Filters';
import CategoryNavigation from './navigation/CategoryNavigation';
import { ISetCategoryFilterOptionPayload, resetAllFilter, setCategoryFilterOption } from '../../actions/filter';
import {
    ICategoryTreeItem,
    IPagination,
    ISort,
    IStoreConfig,
    ICategoryFilter,
    ISelectFilter,
    IApiQueryData
} from '../../interfaces';
import FilterStateService from '../../services/FilterStateService';
import Button from '../../../../../react/common/components/form/Button';
import SearchInput from '../../../../../react/common/components/form/SearchInput';
import { ISetSearchFieldTextPayload, setSearchFieldText } from '../../actions/search';
import Loading from '../../../../../react/common/components/Loading';
import { Filter } from '../../types';
import { isCategoryFilter } from '../../includes/searchPageHelper';
import { updateActiveCategory } from '../../actions/categoriesTree';
import { FilterNames } from '../../../../../catalog-page/constants';
import MyBikeFilter from '../../../../../catalog-page/components/sidebar/filters/mybike-filter/MyBikeFilter';
import Utils from '../../../../../utils/utils';
import SearchFieldLabel from './SearchFieldLabel';
import { PARAM_SEARCH } from '../../constants';
import useFindCategoryByKey from '../../hooks/useFindCategoryByKey';
import { scrollToTopElement } from 'PyzShopUi/scripts/helpers/scrollHelpers';
import {ACCORDION_SCROLLABLE_PARENT_CLASS_NAME} from 'PyzShopUi/scripts/shop-ui/constants';
import { executeScrollCommand } from 'PyzShopUi/scripts/shop-ui/services/ScrollBackService';
import { useTranslationHook } from 'PyzShopUi/scripts/utils/translationHook';

interface ISidebarProps {
    apiQueryData: IApiQueryData;
    pagination: IPagination;
    sort: ISort;
    filters: Filter[];
    filtersMapping: any;
    isProgressing: boolean;
    filterStateService: FilterStateService;
    dispatch: React.Dispatch<any>;
    renderPerPageSelection: boolean;
    renderSortSelection: boolean;
    renderTextSearchField: boolean;
    filtersShowResultsText?: string;
    filtersHeadlineText?: string;
    filtersHeadlineTextSmall?: string;
    perPageAndSortHeadline?: string;
    storeConfig?: IStoreConfig;
    navigationActiveCategoryKey?: string;
    categoriesTree?: ICategoryTreeItem[];
}

const SortFilterSidebar: React.FunctionComponent<ISidebarProps> = props => {
    const {
        pagination,
        sort,
        filters,
        filtersMapping,
        filterStateService,
        isProgressing,
        dispatch,
        navigationActiveCategoryKey,
        categoriesTree,
        storeConfig,
        renderTextSearchField,
        renderPerPageSelection,
        renderSortSelection,
    } = props;
    const { translate } = useTranslationHook();

    const [sidebarActive, setSidebarActive] = React.useState<boolean>(false);
    const [searchInputValue, setSearchInputValue] = React.useState<string>(Utils.getURLParamValue(PARAM_SEARCH) || '');
    const trapFocusRef = React.useRef<HTMLDivElement>();
    const buttonRef = React.useRef<HTMLButtonElement>();

    const itemPerPageOptions: number[] = pagination?.config?.validItemsPerPageOptions || [];
    const currentItemsPerPage: string = pagination?.currentItemsPerPage?.toString() || '';
    const pageNumFound: number = pagination?.numFound || 0;

    const sortParamNames: string[] = sort?.sortParamNames || [];
    const currentSortParam: string = sort?.currentSortParam || 'capacity';

    const perPageAndSortHeadline = props.perPageAndSortHeadline ?? translate('Articles per page & sorting', { ns: 'article-list'});
    const filtersSectionHeadlineTextSmall = props.filtersHeadlineTextSmall ?? translate('Filter & Sorting', { ns: 'article-list'});
    const filtersSectionHeadlineText = props.filtersHeadlineText ?? translate('Filters', { ns: 'article-list'});
    const filtersShowResultsText = translate(props.filtersShowResultsText ?? 'showArticle', {
        count: pageNumFound,
        ns: 'search-page-filter'
    });

    const activityGroupClassNames = classNames(
        'sidebar__activity-group',
        {
            [ACCORDION_SCROLLABLE_PARENT_CLASS_NAME]: sidebarActive,
        },
    );

    const wrapTransition = (element: React.ReactNode) => {
        return <CSSTransition appear={true} in={sidebarActive} classNames="sidebar__activity-group--active" timeout={100} >
            {element}
        </CSSTransition>
    }

    const onToggleSidebar = () => setSidebarActive(!sidebarActive);
    const onResetAllFilter = () => {
        setSearchInputValue('');
        dispatch(resetAllFilter());
        scrollToTopElement();
        trapFocusRef.current?.focus();
    };

    const categoryFilter = filters.find(filter => isCategoryFilter(filter)) as ICategoryFilter;
    const isCategoryTreeExist = categoriesTree?.length > 0;


    React.useEffect(() => {
        if (!categoriesTree || categoriesTree.length === 0) {
            return;
        }

        executeScrollCommand();
    }, [categoriesTree ? categoriesTree.length : 0]);

    React.useEffect(() => {
        if(sidebarActive) {
            trapFocusRef.current?.focus();
        }
    }, [sidebarActive])

    const isAnyFilterActive = (): boolean => {
        for (const filter of filters) {
            if (filterStateService.isFilterActive(filter)) {
                return true;
            }
        }

        if (searchInputValue) {
            return true;
        }

        return !isCategoryTreeExist && categoryFilter && categoryFilter.selectedValue !== null;
    };

    const isDisplaySidebarResetAllButton = isAnyFilterActive();

    const displaySmallScreenResetAllButton = () => {
        if (!isDisplaySidebarResetAllButton) {
            return null;
        }

        return (
            <div className="button-reset-all-selected-filter-option">
                <div
                    onClick={() => onResetAllFilter()}
                    className="tag-item"
                    role="button"
                    tabIndex={0}
                    onKeyDown={e => {
                        if(e.key === "Enter") {
                            onResetAllFilter()
                        }
                    }}
                >
                    <span>{translate('Reset all filters', { ns: 'article-list'})}</span>
                </div>
            </div>
        );
    };

    const displayLargeScreenSidebarResetAllButton = () => {
        if (!isDisplaySidebarResetAllButton) {
            return null;
        }

        return (
            <Button
                label={translate('Reset all filters', { ns: 'article-list'})}
                color="grey"
                additionalClass="sidebar__activity-group__mobile-button-reset-all hide-for-small-only"
                onClick={onResetAllFilter}
            />
        );
    };

    const displaySmallScreenSidebarResetAllButton = () => {
        if (!isDisplaySidebarResetAllButton) {
            return null;
        }

        return (
            <Button
                label={translate('Reset all filters', { ns: 'article-list'})}
                color="grey"
                additionalClass="sidebar__activity-group__mobile-button-reset-all show-for-small-only"
                onClick={onResetAllFilter}
            />
        );
    };

    const displaySearchAndFilterLabels = () => {
        const selectedLabels: JSX.Element[] = [];

        if (renderTextSearchField && searchInputValue !== '') {
            selectedLabels.push(<SearchFieldLabel
                key={`searchFieldLabel-${searchInputValue}`}
                label={`${translate('Search', { ns: 'article-list'})}: ${searchInputValue}`}
                dispatch={() => onSearchInputChange('', '')}
            />);
        }

        for (const filter of filters) {
            const selectedOptions = filterStateService.getSelectedOptions(filter);

            if (!filtersMapping.hasOwnProperty(filter.name)
                || !filtersMapping[filter.name].hasOwnProperty('handler')
            ) {
                continue;
            }

            const SelectedOptionsTag = filtersMapping[filter.name].handler.getSelectionTagComponent(filter);

            if (SelectedOptionsTag === null) {
                continue;
            }

            selectedLabels.push(<SelectedOptionsTag
                key={filter.name + JSON.stringify(selectedOptions)}
                filter={filter}
                options={selectedOptions}
                formatterFunction={filtersMapping[filter.name].labelFormatter}
                storeConfig={storeConfig}
                dispatch={dispatch}
            />);
        }

        return selectedLabels;
    };

    const onSearchInputChange = (_: string, newInputValue: string) => {
        setSearchInputValue(newInputValue);

        const payload: ISetSearchFieldTextPayload = {
            newSearchTerm: newInputValue,
        };

        dispatch(setSearchFieldText(payload));
    };

    const displaySearchSection = () => {
        if (!renderTextSearchField) {
            return null;
        }

        return (
            <div className={`search-section grid-x`}>
                <div className={`cell`}>
                    <SearchInput
                        hasLabel={false}
                        dataQa={'sidebar-text-search-field'}
                        value={searchInputValue}
                        placeholder={translate('e.g. Honda CBR 600', { ns: 'bike-db'})}
                        isFullWidth={true}
                        onSearchChange={onSearchInputChange}
                        inputProps={{ maxLength: 50 }}
                    />
                </div>
            </div>
        );
    };

    const displayMyBikeFilter = () => {
        const myBikeFilter = filters.find(filter => filter.name === FilterNames.MYBIKE) as ISelectFilter;

        if (!myBikeFilter) {
            return null;
        }

        return <MyBikeFilter
            name={myBikeFilter.name}
            options={myBikeFilter.options}
            dispatch={dispatch}
        />;
    };

    const displayCategoryTree = () => {
        if (!isCategoryTreeExist) {
            return null;
        }

        const onNavigateBack = (items: ICategoryTreeItem[], activeItem: ICategoryTreeItem) => {
            const parentItem = useFindCategoryByKey(items, activeItem.parentKey);
            dispatch(updateActiveCategory(parentItem));
            scrollToTopElement();
        };

        const onNavigateSelect = (item: ICategoryTreeItem) => {
            dispatch(updateActiveCategory(item));
            scrollToTopElement();
        };

        return <CategoryNavigation
            items={categoriesTree}
            activeCategoryKey={navigationActiveCategoryKey}
            onBack={onNavigateBack}
            onSelect={onNavigateSelect}
        />;
    };

    const displayCategoryFilter = () => {
        if (isCategoryTreeExist || !categoryFilter || !categoryFilter.categoryTree) {
            return null;
        }

        const rootCategory: ICategoryTreeItem = {
            id: 1,
            categoryKey: 'ROOT',
            pageTitle: '',
            metaTitle: '',
            seoTitle: '',
            name: '',
            url: '',
            children: categoryFilter.categoryTree
        };

        const onFilterBack = (items: ICategoryTreeItem[], activeItem: ICategoryTreeItem) => {
            const payload: ISetCategoryFilterOptionPayload = {
                filterName: FilterNames.CATEGORY,
                selectedValue: activeItem.parentKey === 'ROOT' ? null : activeItem.parentKey,
            };

            dispatch(setCategoryFilterOption(payload));
            scrollToTopElement();
        };

        const onFilterSelect = (item: ICategoryTreeItem) => {
            const payload: ISetCategoryFilterOptionPayload = {
                filterName: FilterNames.CATEGORY,
                selectedValue: item.categoryKey,
            };

            dispatch(setCategoryFilterOption(payload));
            scrollToTopElement();
        };

        return <CategoryNavigation
            items={[rootCategory]}
            activeCategoryKey={categoryFilter.selectedValue || rootCategory.categoryKey}
            onBack={onFilterBack}
            onSelect={onFilterSelect}
        />;
    };

    const generateSortSelect = (cellClass: string) => (
        <div className={`cell ${cellClass}`}>
            <SortSelect
                sortParamNames={sortParamNames}
                currentSortParam={currentSortParam}
                dispatch={dispatch}
            />
        </div>
    );

    const generatePerPageSelect = (cellClass: string) => (
        <div className={`cell ${cellClass}`}>
            <MdcPerPageSelect
                itemPerPageOptions={itemPerPageOptions}
                currentItemsPerPage={currentItemsPerPage}
                dispatch={dispatch}
            />
        </div>
    );

    const displayPerPageAndSort = () => {
        if (renderPerPageSelection === false && renderSortSelection === false) {
            return null;
        }

        let perPageSelect = null;
        let sortSelect = null;

        if (renderPerPageSelection === false && renderSortSelection === true) {
            perPageSelect = null;
            sortSelect = generateSortSelect('small-12');
        } else if (renderPerPageSelection === true && renderSortSelection === false) {
            perPageSelect = null;
            sortSelect = generatePerPageSelect('small-12');
        } else if (renderPerPageSelection === true && renderSortSelection === true) {
            perPageSelect = generatePerPageSelect('small-4');
            sortSelect = generateSortSelect('small-8');
        }

        return (
            <div className="sorter-section show-for-small-only">
                <span>
                    {perPageAndSortHeadline}
                </span>

                <div className="grid-x grid-margin-x">
                    {perPageSelect}
                    {sortSelect}
                </div>
            </div>
        );
    };

    const displayLoading = React.useCallback(
        () => isProgressing === true ?
            <Loading animationClasses={`hide-for-small-only`}/> : null,
        [isProgressing]
    );

    const displayFilterSection = () => {
        if (!pageNumFound && !isAnyFilterActive()) {
            return null;
        }

        return (
            <div className="filter-section">
                {displayLoading()}

                <div className="filter-section__desktop-label hide-for-small-only">
                    <span className="filter-section__desktop-label__text heading3">{filtersSectionHeadlineText}</span>

                </div>

                {displaySearchSection()}

                <Filters
                    filters={filters}
                    filtersMapping={filtersMapping}
                    storeConfig={storeConfig}
                    dispatch={dispatch}
                />
            </div>
        );
    };

    const displaySidebarButton = () => {
        if (!filters.length) {
            return null;
        }

        return (
            <Button
                label={filtersSectionHeadlineTextSmall}
                icon="filter"
                additionalClass="show-for-small-only button button-filter button-bordered button-leading-icon button-open-filter button-grey"
                onClick={onToggleSidebar}
                dataQa="trigger-open-sorting-and-filters"
            />
        );
    };

    return (
        <div className={`sidebar`}>
            {displaySidebarButton()}

            <div className="sidebar__label-item-section show-for-small-only">
                <div className="selected-filter-option-list">
                    {displaySearchAndFilterLabels()}
                </div>

                {displaySmallScreenResetAllButton()}
            </div>

            {wrapTransition(
            <div className={activityGroupClassNames}>
                <div
                    ref={trapFocusRef}
                    className='show-for-small-only'
                    role="button"
                    aria-label="Focus Trap"
                    tabIndex={0}
                    style={{ outline: "none" }}
                    onKeyDown={e => {
                        if (e.key === "Tab" && e.shiftKey) {
                            e.preventDefault();
                            buttonRef?.current?.focus();
                        }
                    }}
                    >
                </div>
                <div className="sidebar__activity-group__mobile-label show-for-small-only">
                    <span> {filtersSectionHeadlineTextSmall} </span>
                </div>

                {displayPerPageAndSort()}

                {displayMyBikeFilter()}

                {displayCategoryTree()}

                {displayCategoryFilter()}

                {displayFilterSection()}

                {displayLargeScreenSidebarResetAllButton()}

                <div className="button-show-list show-for-small-only">
                    {displaySmallScreenSidebarResetAllButton()}
                    <Button
                        buttonRef={buttonRef}
                        label={filtersShowResultsText}
                        icon="arrow"
                        iconAdditionalClass="icon-size-small icon-arrow-filter"
                        additionalClass="button button-progressing button-red"
                        onClick={onToggleSidebar}
                        onKeyDown={e => {
                            if (e.key === "Tab" && !e.shiftKey) {
                                e.preventDefault();
                                trapFocusRef?.current?.focus();
                            }
                        }}
                        isProgressing={isProgressing}
                        iconPosition="right"
                        dataQa="trigger-close-sorting-and-filters"
                    />
                </div>
            </div>
            )}
        </div>
    );
};

export default SortFilterSidebar;
