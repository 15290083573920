import * as React from 'react';
import { sprykerProductImageSrcSet } from '../../../../react/common/lib';
import { ISearchSuggestionProductItem } from '../../../interfaces';
import { useTranslationHook } from 'PyzShopUi/scripts/utils/translationHook';

interface ISuggestionProductItemProps {
    product: ISearchSuggestionProductItem;
    dataTrackAttr?: {};
}

const SuggestionProductItem: React.FunctionComponent<ISuggestionProductItemProps> = props => {
    const { product } = props;
    const { translate } = useTranslationHook();

    //Build product name for accessibility
    let productName = '';
    if (product.brandName) productName += product.brandName += ' '; //This is not a required field
    if (product.abstractName) productName += product.abstractName;
    const imgAlt = productName?.length < 10 ? `${translate('product', {ns: 'url'})} ${productName}` : productName;
    return (
        <div className="search-suggestions-item-product">
            <a className="search-suggestions-item-product-link" href={product.url} aria-label={imgAlt}/>
            <div className="search-suggestions-item-product-image">
                <div className="product-image-cover lazy-image-container">
                    <img
                        alt={imgAlt}
                        src={product.images.externalUrlThumbnail}
                        className="product-image lazyload"
                        data-src={product.images.externalUrlThumbnail}
                        data-srcset={sprykerProductImageSrcSet(product.images)}
                    />
                </div>
            </div>
            <div className="search-suggestions-item-product-info">
                <div className="search-suggestions-item-product-info-brand" {...props.dataTrackAttr}>
                    {product.brandName}
                </div>
                <div className="search-suggestions-item-product-info-name" {...props.dataTrackAttr}>
                    {product.abstractName}
                </div>
            </div>
        </div>
    );
};

export default SuggestionProductItem;
