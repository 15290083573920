import {
    PARAM_CATEGORY_KEY,
    PARAM_PAGE
} from 'PyzShopUi/scripts/shop-ui/components/search-page/constants';
import {
    IApiQueryData,
    ICategoryTreeItem,
    ISearchPageActionState
} from 'PyzShopUi/scripts/shop-ui/components/search-page/interfaces';
import { categoriesTreeService } from 'PyzShopUi/scripts/shop-ui/components/search-page/services/CategoriesTreeService';

export const doUpdateCategoriesTree = (
    state: ISearchPageActionState,
    payload: ICategoryTreeItem[]
): ISearchPageActionState => {
    const categoriesTree = categoriesTreeService.updateCategoryTreeWithCount(
        payload,
        state.extraInformation.productCountByCategory
    );

    return ({
        ...state,
        categoriesTree
    });
};

export const doUpdateNavigationActiveCategoryKey = (
    state: ISearchPageActionState,
    activeCategoryKey?: string | null
): ISearchPageActionState => ({
    ...state,
    navigationActiveCategoryKey: activeCategoryKey || state.initCategoryKey
});

export const doUpdateActiveCategory = (
    state: ISearchPageActionState,
    activeCategory: ICategoryTreeItem
): ISearchPageActionState => {
    const newApiQueryData: IApiQueryData = {
        ...state.apiQueryData,
        [PARAM_PAGE]: '1',
        [PARAM_CATEGORY_KEY]: activeCategory.categoryKey,
    };

    return {
        ...state,
        apiQueryData: newApiQueryData,
        navigationActiveUrl: activeCategory.url,
        navigationActiveCategoryKey: activeCategory.categoryKey,
        activeCategoryName: activeCategory.seoTitle,
        headTitle: activeCategory.pageTitle
    };
};
