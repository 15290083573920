import * as React from 'react';
import { ISearchSuggestion } from '../interfaces';
import usePopulateSearchSuggestionData from '../hooks/usePopulateSearchSuggestionData';
import SuggestionProductList from '../components/suggestion/product/SuggestionProductList';
import SuggestionItems from '../components/suggestion/default/SuggestionItems';
import classNames from 'classnames';

interface ISuggestionContainerProps {
    data: ISearchSuggestion[];
    searchValue?: string;
}

const SearchSuggestionResultContainer: React.FunctionComponent<ISuggestionContainerProps> = props => {
    const { data, searchValue } = props;

    const { category, brand, product, bike, store, servicePage } = usePopulateSearchSuggestionData(data);

    const hasLeftColumn = React.useCallback(() => !(!category && !brand), [category, brand]);
    const hasRightColumn = React.useCallback(() => !(!bike && !store && !servicePage), [bike, store, servicePage]);
    const dataTrackAttr = {
        "data-track-id": "suggestSearchTerm"
    };
    const displayLeftColumn = React.useCallback(() => {
        if (!hasLeftColumn()) {
            return null;
        }

        return (
            <div className="search-suggestions-column">
                <SuggestionItems data={category} searchTerm={searchValue} dataTrackAttr={dataTrackAttr} />
                <SuggestionItems data={brand} searchTerm={searchValue} dataTrackAttr={dataTrackAttr}/>
            </div>
        );
    }, [hasLeftColumn(), brand, category]);

    const displayMiddleColumn = React.useCallback((forMobile: boolean) => {
        if (!product) {
            return null;
        }

        const columnClasses = classNames(
            'search-suggestions-column',
            {
                'hide-for-small-only': !forMobile,
                'show-for-small-only': forMobile
            }
        );

        return (
            <div className={columnClasses}>
                <SuggestionProductList data={product} searchValue={searchValue} />
            </div>
        );
    }, [product]);

    const displayRightColumn = React.useCallback(() => {
        if (!hasRightColumn()) {
            return null;
        }

        return (
            <div className="search-suggestions-column">
                <SuggestionItems data={bike} searchTerm={searchValue} dataTrackAttr={dataTrackAttr}/>
                <SuggestionItems data={store} searchTerm={searchValue} dataTrackAttr={dataTrackAttr}/>
                <SuggestionItems data={servicePage} searchTerm={searchValue} dataTrackAttr={dataTrackAttr}/>
            </div>
        );
    }, [hasRightColumn(), bike, store, servicePage]);

    const suggestionClasses = classNames(
        'search-suggestions',
        {
            'search-suggestions-single-mode': !hasLeftColumn() && !hasRightColumn()
        }
    );

    return (
        <div className="grid-container">
            <div className={suggestionClasses}>
                {displayMiddleColumn(true)}
                {displayLeftColumn()}
                {displayMiddleColumn(false)}
                {displayRightColumn()}
            </div>
        </div>
    );
};

SearchSuggestionResultContainer.defaultProps = {
    searchValue: ''
}

export default SearchSuggestionResultContainer;
