import * as React from 'react';
import { withMemo } from 'PyzShopUi/scripts/react/common/hoc/withMemo';

interface IHeaderItemCounterProps {
    totalSearchResultItemsText: string;
    dataTrackAttr?: [];
}

const HeaderItemCounterComponent: React.FunctionComponent<IHeaderItemCounterProps> = props => (
    <span {...props.dataTrackAttr}>{props.totalSearchResultItemsText}</span>
);

const HeaderItemCounter = withMemo(HeaderItemCounterComponent);

export default HeaderItemCounter;
