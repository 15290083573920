import * as React from 'react';
import { withMemo } from 'PyzShopUi/scripts/react/common/hoc/withMemo';

interface IJacketBuyerGuideLadingState {
    categoryKey: string;
    bannerContent: string;
}

const categoryKeysHavingBuyerGuide = ['c-770', 'c-772', 'c-806', 'c-852', 'c-870'];

const JacketBuyerGuideComponent: React.FunctionComponent<IJacketBuyerGuideLadingState> = props => {
    const { categoryKey, bannerContent } = props;

    if (!categoryKeysHavingBuyerGuide.includes(categoryKey) || !bannerContent) {
        return null;
    }

    return (
        <div dangerouslySetInnerHTML={{ __html: bannerContent }} />
    );
};

export const JacketBuyerGuideLanding = withMemo(JacketBuyerGuideComponent);
